"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var js_api_loader_1 = require("@googlemaps/js-api-loader");
var Maps = /** @class */ (function () {
    function Maps() {
        if (window.wordpress_globals && window.wordpress_globals.google_maps_api_key) {
            var googleMapWrappers = document.querySelectorAll("[data-google-map]");
            googleMapWrappers.forEach(function (mapEl) {
                var map = null;
                //latitude and langitude from the map element ensure it's set
                var lat = parseFloat(mapEl.dataset.lat);
                var lng = parseFloat(mapEl.dataset.lng);
                //map options
                var mapOptions = {
                    center: {
                        lat: lat,
                        lng: lng,
                    },
                    zoom: 16,
                    styles: window.wordpress_globals.google_maps_style,
                    streetViewControl: false,
                    mapTypeControl: false,
                    zoomControl: false,
                    fullscreenControl: false,
                    keyboardShortcuts: false,
                };
                //loading google maps API
                var loader = new js_api_loader_1.Loader({
                    apiKey: window.wordpress_globals.google_maps_api_key,
                    version: "weekly",
                });
                loader
                    .load()
                    .then(function (google) {
                    map = new google.maps.Map(mapEl, mapOptions);
                    //google maps marker
                    if (window.wordpress_globals.google_maps_marker) {
                        var icon = {
                            url: window.wordpress_globals.google_maps_marker,
                            scaledSize: new google.maps.Size(94, 94),
                            // origin: new google.maps.Point(0,0), // origin
                            anchor: new google.maps.Point(47, 47) // anchor
                        };
                        var marker = new google.maps.Marker({
                            position: {
                                lat: lat,
                                lng: lng,
                            },
                            map: map,
                            icon: icon,
                        });
                    }
                })
                    .catch(function (e) {
                    console.error(e);
                });
            });
        }
    }
    return Maps;
}());
exports.default = Maps;
