"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var HoverBlock = /** @class */ (function () {
    function HoverBlock() {
        var hoverCopy = document.querySelectorAll('.js-hover-copy');
        hoverCopy.forEach(function (hover) {
            var text = hover.querySelector('.js-hover-text');
            var title = hover.querySelector('.js-hover-title');
            var totalHeight = hover.getBoundingClientRect().height;
            var titleHeight = title.getBoundingClientRect().height;
            var textHeight = text.getBoundingClientRect().height;
            hover.style.transform = "translateY(".concat(textHeight - 2, "px)");
            hover.style.opacity = "1";
            console.log("total height: " + totalHeight + " title height: " + titleHeight + " text height: " + textHeight);
            /*
            TODO: calculate total height of the copy
            set initial positon of that of the title
             translate accordingly
             */
        });
    }
    return HoverBlock;
}());
exports.default = HoverBlock;
