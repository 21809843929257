"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var VideoGrid = /** @class */ (function () {
    function VideoGrid(el) {
        this.modalEls = [];
        this.posterEls = [];
        this.el = el;
        this.init();
    }
    VideoGrid.create = function (el) {
        new VideoGrid(el);
    };
    VideoGrid.prototype.openModal = function (index) {
        var _this = this;
        var _a;
        this.modalEls[index].showModal();
        (_a = this.modalEls[index].querySelector(".video-grid__modal-close")) === null || _a === void 0 ? void 0 : _a.addEventListener("click", function () {
            _this.closeModal(_this.modalEls[index]);
        });
    };
    VideoGrid.prototype.closeModal = function (modelEl) {
        // pause video
        var videoEl = modelEl.querySelector(".video-grid__modal-content video");
        if (videoEl) {
            videoEl.pause();
        }
        // close modal
        modelEl.close();
    };
    VideoGrid.prototype.init = function () {
        var _this = this;
        var _a, _b;
        this.modalEls = Array.from((_a = this.el.querySelectorAll(".video-grid__modal")) !== null && _a !== void 0 ? _a : []);
        this.posterEls = Array.from((_b = this.el.querySelectorAll(".video-grid__poster")) !== null && _b !== void 0 ? _b : []);
        this.posterEls.forEach(function (posterEl, index) {
            posterEl.addEventListener("click", function () {
                _this.openModal(index);
            });
        });
        // outside video-grid__modal-content click
        this.modalEls.forEach(function (modalEl) {
            modalEl.addEventListener("click", function (e) {
                if (e.target === modalEl) {
                    _this.closeModal(modalEl);
                }
            });
        });
    };
    return VideoGrid;
}());
exports.default = VideoGrid;
