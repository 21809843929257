"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Dialog = /** @class */ (function () {
    function Dialog(element) {
        var _a;
        this.trigger = element;
        var dialogSelector = (_a = element.dataset.modal) !== null && _a !== void 0 ? _a : null;
        console.log("Dialog selector: ", dialogSelector);
        if (dialogSelector === null) {
            console.warn("Dialog trigger missing data-modal attribute");
            return;
        }
        this.dialog = document.querySelector(dialogSelector);
        if (this.dialog && this.dialog instanceof HTMLDialogElement) {
            this.initEvents();
        }
        else {
            console.warn("Dialog element incorrectly initialised");
        }
    }
    Dialog.prototype.open = function (event) {
        event.preventDefault();
        this.dialog.scrollTo(0, 0);
        this.dialog.showModal();
    };
    Dialog.prototype.close = function (event) {
        event.preventDefault();
        this.dialog.close();
    };
    Dialog.prototype.closeIfBackdropClicked = function (event) {
        var rect = this.dialog.getBoundingClientRect();
        var isInDialog = (rect.top <= event.clientY && event.clientY <= rect.top + rect.height &&
            rect.left <= event.clientX && event.clientX <= rect.left + rect.width);
        if (!isInDialog) {
            this.dialog.close();
        }
    };
    Dialog.prototype.initEvents = function () {
        this.trigger.addEventListener("click", this.open.bind(this));
        this.dialog.addEventListener("click", this.closeIfBackdropClicked.bind(this));
        this.dialog.querySelector(".btn").addEventListener("click", this.close.bind(this));
    };
    return Dialog;
}());
exports.default = Dialog;
